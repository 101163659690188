import { toast } from "react-hot-toast";
import Backdrop from "../Backdrop";
import CloseButton from "../CloseButton";
import { AiOutlineUser } from "react-icons/ai";
import { useForm } from "react-hook-form";
import ErrorText from "../Errors/ErrorText";
import { Button } from "../Button";
import Rule from "../Rule/Index";
import { errorBlock } from "../../controllers/errorBlock";
import axios from "../../utils/axios";
import { channelController } from "../../controllers/channelController";
import { useState } from "react";
import { setToken } from "../../utils/token";
import { updateRegistration } from "../../redux/slice/profileSlice";
import { useDispatch } from "react-redux";

const ProfileModal = ({ open, setopen }) => {
  const [loading, setloading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      setloading(true);

      const { firstname, lastname } = data;
      let res = await axios.post("/authselfhelp/completeregistration", {
        firstname,
        lastname,
      });

      let result = channelController(res);

      if (result?.type !== "success") {
        toast[result.type](result?.message);
        setloading(false);
        return;
      }
      dispatch(updateRegistration(true));

      setloading(false);
      toast.success("Profile updated.");
      setopen(false);
    } catch (err) {
      errorBlock(err);
      setloading(false);
    }
  };
  return (
    <Backdrop open={open}>
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="w-[400px] bg-white rounded-md p-3 animate__animated animate__fadeInUp">
          <div className="flex items-start justify-content-between">
            <div className="">
              <div className="font-Kanit font-semibold text-[17px]">
                Update profile
              </div>
              <div className=" text-gray-700 font-light">
                Please fill in the fields to update your profile.
                <br />
              </div>
            </div>
            <div
              className=" w-[200px] flex justify-end"
              onClick={() => setopen(false)}
            >
              <CloseButton closeBtn={setopen} />
            </div>
          </div>
          <Rule />
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* firstname */}
            <div className="mt-4">
              <label className="text-primary font-normal text-sm">Email</label>
              <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[45px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    type={"firstname"}
                    {...register("firstname", {
                      required: true,
                    })}
                    placeholder={"Enter your first name"}
                    className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                  />
                </div>
              </div>
              {errors.firstname && (
                <ErrorText text={"Please enter your firstname"} />
              )}
            </div>
            {/* lastname */}
            <div className="mt-2">
              <label className="text-primary font-normal text-sm">
                Lastname
              </label>
              <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[45px]">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    size={20}
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2">
                  <input
                    type={"lastname"}
                    {...register("lastname", {
                      required: true,
                    })}
                    placeholder={"Enter your last name"}
                    className=" placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                  />
                </div>
              </div>
              {errors.lastname && (
                <ErrorText text={"Please enter your lastname"} />
              )}
            </div>
            <div className="mt-6 mb-3">
              <Button text={"Submit"} loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </Backdrop>
  );
};

export default ProfileModal;
