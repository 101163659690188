import { createContext, useState, useEffect, useContext, useRef } from "react";
import { getToken } from "../utils/token";
import { channelController } from "../controllers/channelController";
import axios from "../utils/axios";
import { toast } from "react-hot-toast";
import ProfileModal from "../components/ProfileModal";
import { useSelector } from "react-redux";
const ProfileContext = createContext();

export const useMQTT = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const [loading, setloading] = useState(true);
  const authState = useSelector((state) => state.authReducer);

  const { verification, registration } = useSelector((state) => state.profiles);

  // show regiration modal of registration is false
  const [open, setopen] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("spiral_token");
    // token &&

    if (authState?.loggedIn == true) {
      setopen(registration ? false : true);
    }
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const status = await getToken("spiral_registration", "notverified");
  //       if (status == "notverified" || !status) {
  //         setopen(true);
  //       }
  //       // let username = await getToken("spiral_username");
  //       // let res = await axios.post("/auth/account/getuser", {
  //       //   username,
  //       // });

  //       // let result = channelController(res);
  //       // console.log(result, "result hello");
  //     } catch (err) {
  //       if (err.response) {
  //         toast.error(err.response.data.response);
  //         return;
  //       }
  //       toast.error(err.message);
  //     }
  //   })();
  //   return () => {};
  // }, []);

  return (
    <ProfileContext.Provider
      value={{
        loading,
      }}
    >
      <ProfileModal open={open} setopen={setopen} />
      {children}
    </ProfileContext.Provider>
  );
};
