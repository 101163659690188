import NotFoundImg from "../../assets/png/Screenshot 2023-03-20 at 18.13.07.png";
import { Link, useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen  items-center justify-center flex bg-white">
      <div className="flex items-center flex-col justify-center mt-[-50px] rounded-md p-3">
        <div className="w-[250px] h-[250px]">
          <img src={NotFoundImg} alt="" className="" />
        </div>
        <div className=" text-gray-800">
          You have entered an unknown page!!!.
        </div>
        <div
          className="text-center mt-6  underline text-blue-500 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          Go back
        </div>
      </div>
    </div>
  );
};

export default NotFound;
