import axios from "../../utils/axios";
import { getToken } from "../../utils/token";
import { errorBlock } from "../../controllers/errorBlock";
import {
  balanceCompleted,
  balancePending,
  userBalance,
  walletHistory,
} from "../slice/billing.slice";
import { billingController } from "../../controllers/BillingController";
import toast from "react-hot-toast";

export const patchBilling =
  (channelid, rate, units) => async (dispatch, getState) => {
    try {
      let user = await getToken("spiral_username");
      let res = axios.post("/submeterbilling/setbillingmode", {
        username: user,
        channelid: channelid,
        rate: rate,
        minvend: units,
      });

      return res;
    } catch (err) {
      errorBlock(err.message);
    }
  };

export const disableBilling = (channelid) => async (dispatch) => {
  try {
    let username = await getToken("spiral_username");
    let res = axios.post("/submeterbilling/disablekwhbilling", {
      username,
      channelid: channelid,
    });
    return res;
  } catch (err) {
    errorBlock(err);
  }
};

export const getWalletBalance = (currency) => async (dispatch) => {
  try {
    let username = await getToken("spiral_username");
    let res = await axios.post("/transaction/getwalletbalance", {
      username,
      currency,
    });

    const result = billingController(res);
    if (result.type != "success") {
      return toast.error(result.message);
    }

    dispatch(userBalance(result.message.response));
  } catch (err) {
    errorBlock(err);
  }
};

export const getWallethistory =
  (startdate, enddate, limit, offset) => async (dispatch) => {
    try {
      let username = await getToken("spiral_username");
      let res = await axios.post("/transaction/getwallettransactionhistory", {
        username,
        startdate,
        enddate,
        limit,
        offset,
      });

      const result = billingController(res);
      if (result.type != "success") {
        return toast.error(result.message);
      }

      dispatch(walletHistory(result.message?.body));
      dispatch(balanceCompleted());
    } catch (err) {
      errorBlock(err);
      dispatch(balanceCompleted());
    }
  };
