import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  balance: 0,
  walletHistory: [],
  loading: true,
};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    userBalance: (state, action) => {
      state.balance = action.payload;
    },
    walletHistory: (state, action) => {
      state.walletHistory = action.payload;
    },
    balancePending: (state, action) => {
      state.loading = true;
    },
    balanceCompleted: (state, action) => {
      state.loading = false;
    },
  },
});

export const { userBalance, walletHistory, balancePending, balanceCompleted } =
  billingSlice.actions;

export const billingReducer = billingSlice.reducer;
