import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileDetails: null,
  registration: false,
  verification: false,
};

export const profileSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    updateProfile: (state, action) => {
      state.profileDetails = action.payload;
    },
    updateEmailVerification: (state, action) => {
      state.verification = action.payload;
    },
    updateRegistration: (state, action) => {
      console.log(action.payload, "Got in");
      state.registration = action.payload;
    },
  },
});

export const { updateProfile, updateEmailVerification, updateRegistration } =
  profileSlice.actions;

export const profileReducer = profileSlice.reducer;
