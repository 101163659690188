import React, { useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import axios from "../../../../utils/axios";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import ProcessingCard from "../../../Loader/ProcessingCard";
import { channelController } from "../../../../controllers/channelController";
import ViewPaymentPlan from "../../../PaymentPlan/Modals/ViewPaymentPlan";
const AddPlanToChannelTable = ({ data }) => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [selectedplan, setselectedplan] = useState(null);
  const [modal, setmodal] = useState(false);
  const hanldeAddChannel = async (alias) => {
    try {
      setloading(true);
      let res = await axios.post(
        "/submeter/paymentplanassignment/assignplantochannel",
        {
          planalias: alias,
          channelid: id,
        }
      );
      const result = channelController(res);
      if (result.type != "success") {
        toast.error("Failed to assign plan to meter");
        setloading(false);
        return;
      }

      toast.success("Plan assigned to meter successfully");
      setloading(false);
      navigate(`/channel/${id}`);
    } catch (err) {
      toast.err(err.message);
      setloading(false);
    }
  };

  const columns = [
    {
      name: "Plan name",
      selector: (row) => row.planalias,
      center: true,
      sortable: true,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div>{item.planalias}</div>
          </div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      center: true,
      grow: 2,
    },
    {
      name: "Description",
      center: true,
      selector: (row) => row.description,
      sortable: true,
      grow: 2,
    },
    {
      name: "Active period",
      center: true,
      selector: (row) => row.duration,
      sortable: true,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="text-[12px]">
              {item?.duration} {item?.durationlength}
              {+item?.duration > 1 ? "s" : ""}
            </div>
          </div>
        );
      },
    },
    {
      name: "Date created",
      center: true,
      selector: (row) => row.dateassigned,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[12px]">
              {" "}
              {moment(item.datecreated).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.deviceid,
      grow: 3,
      cell: (item) => {
        return (
          <div className="flex rounded-md items-center justify-between">
            <div
              onClick={() => {
                setselectedplan(item);
                setmodal(true);
              }}
              className="p-1 border-[1px] border-gray-400 rounded-sm mr-3 cursor-pointer"
            >
              Details
            </div>
            {
              <div
                onClick={() =>
                  loading ? null : hanldeAddChannel(item?.planalias)
                }
                className="p-1 border-[1px] border-gray-400 rounded-sm cursor-pointer"
              >
                {loading ? "Assigning..." : "Add plan to channels"}
              </div>
            }
          </div>
        );
      },
    },
  ];

  return (
    <>
      {modal && (
        <ViewPaymentPlan
          setopen={setmodal}
          open={modal}
          refreshBtn={null}
          details={selectedplan}
        />
      )}
      <DataTable
        style={{
          height: 400,
        }}
        columns={columns}
        data={data}
        pagination
        paginationPerPage={10}
      />
    </>
  );
};

export default AddPlanToChannelTable;
