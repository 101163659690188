import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Button } from "../../../components/Button";
import { useEffect, useState, useRef } from "react";
import { Link, navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken, setToken } from "../../../utils/token";
import { toast } from "react-hot-toast";
import { errorBlock } from "../../../controllers/errorBlock";
import AuthBg from "../../../assets/png/1162369_ORH93N1345678.png";
import Typed from "typed.js";
import { useDispatch, useSelector } from "react-redux";
import { postLogin } from "../../../redux/middleware/auth.action";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";
import { headers } from "../../../utils/headers";
import {
  updateEmailVerification,
  updateRegistration,
} from "../../../redux/slice/profileSlice";

const SignIn = () => {
  const controller = new AbortController();
  const [resetpassword, setresetpassword] = useState(true);
  const authState = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [password, setpassword] = useState("");
  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [loading, setloading] = useState(false);
  const [eye, seteye] = useState(false);
  const [appInstalled, setappInstalled] = useState(
    JSON.parse(localStorage.getItem("installed")) == 1 ? 1 : 0
  );

  const [warninglabel, setwarninglabel] = useState({
    color: "orange",
    text: "",
  });
  let navigate = useNavigate();
  const [installApp, setInstallApp] = useState(
    JSON.parse(localStorage.getItem("installed")) == 1 ? false : true
  );

  // check if the user is loggedIn
  useEffect(() => {
    (async () => {
      const token = await getToken("spiral_token");
      if (authState?.loggedIn && token) {
        navigate("/dashboard");
      }
    })();
  }, []);
  const [installPrompt, setInstallPrompt] = useState(null);

  const [prompt, setPrompt] = useState(false);

  // try to show the pws
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // console.log(e, "event");
      //  const { outcome } = await installPrompt.userChoice;
      setInstallPrompt((prev) => e);
      // console.log(e);
      setPrompt((prev) => true);
      // console.log(`'beforeinstallprompt' event was fired.`);
    });
  }, []);

  const handleInstall = async () => {
    try {
      // setPrompt((prev) => false);
      // console.log(installPrompt);
      installPrompt.prompt();
      const { outcome } = await installPrompt.userChoice;

      if (outcome.includes("accepted")) {
        console.log("App installed ");
        toast.success("App installed succesfully");
        localStorage.setItem("installed", 1);
        return;
      } else if (outcome.includes("dismissed")) {
        console.log("dismissed");

        localStorage.setItem("installed", 0);
        return null;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const [tabState, settabState] = useState(1);
  let domain = "IKJ";

  const element = useRef(null);
  const typed = useRef(null);

  const handleLogin = async () => {
    try {
      if (!(email && password)) {
        toast.error("Both values are required.");
        return;
      }

      setloading(true);
      let res = await axios.post(
        "https://api.app.chanels.io/auth/login",
        {
          username: email,
          password: password,
          subdomain: "app",
        },
        headers
      );

      if (res.data.response == "failed login warning") {
        setwarninglabel({
          color: "orange",
          text: `You have 4 attempts left to login.
           If login fails after that you account will be locked for 24hours.`,
        });
        setloading(false);
        setTimeout(() => {
          setwarninglabel({
            color: "",
            text: "",
          });
        }, 4000);
        return;
      }

      dispatch(updateEmailVerification(res.data.codeverification));
      dispatch(updateRegistration(res.data.registration));

      // console.log(res.data, "response data");
      // console.log(res, "result");
      if (res.data.response === "success") {
        // toast.success("Logged in successfully");
        await setToken("spiral_token", res.data.sessionid);
        await setToken("spiral_username", res.data.username);

        if (!res.data.codeverification && res.data.registration) {
          // await setToken("spiral_email", "notverified");
          // await setToken("spiral_registration", "verified");
          setloading(false);
          // send otp code
          let res = await axios.post(
            "https://api.app.chanels.io/authselfhelp/resendverificationcode",
            {
              callerid: email,
              subdomain: "app",
            },
            headers
          );

          if (res.data.response == "success") {
            toast.success("A code has been sent to your email address.");
            navigate(`/emailverification?email=${email}`);
            return;
          } else if (res.data.response == "failure") {
            setloading(false);
            toast.error(res.data.response, {
              toastId: "32uwcasc",
            });
            return;
          }
        }
        if (res.data.codeverification && !res.data.registration) {
          // await setToken("spiral_email", "verified");
          // await setToken("spiral_registration", "notverified");
          // await setToken("spiral_role", "User");
          setloading(false);
          dispatch(postLogin());
          navigate("/dashboard");
          return;
        }
        if (res.data.registration && res.data.codeverification) {
          // await setToken("spiral_email", "verified");
          // await setToken("spiral_registration", "verified");
          // await setToken("spiral_role", "User");

          setloading(false);
          dispatch(postLogin());
          window.location.href = "/dashboard";
          // navigate("/dashboard");
          return;
        }
        if (!res.data.registration && !res.data.codeverification) {
          // await setToken("spiral_email", "notverified");
          // await setToken("spiral_registration", "notverified");
          let res = await axios.post(
            "https://api.app.chanels.io/authselfhelp/resendverificationcode",
            {
              callerid: email,
              subdomain: "app",
            },
            headers
          );

          if (res.data.response == "success") {
            toast.success("A code has been sent to your email address.");
            setloading(false);
            navigate(`/emailverification?email=${email}&username=${email}`);
            return;
          } else if (res.data.response == "failure") {
            setloading(false);
            toast.error(res.data.response);
            return;
          }
        }
      }
    } catch (err) {
      setloading(false);
      errorBlock(err);
    }
  };

  return (
    // bg-[#f4f4f4]
    <div className="h-screen  grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-items-center place-content-center gap-10">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="w-full z-50 md:w-[500px] mx-auto bg-white rounded-md shadow-md">
        {prompt && installApp == true && (
          <div className="flex p-2 bg-[#e8effa] items-center justify-between border-b-[1px] border-blue-300">
            <div className="">Install this software on your device ?</div>
            <div className="flex items-center">
              <button
                onClick={() => handleInstall()}
                className="text-white cursor-pointer px-2 py-1 rounded-sm bg-green-600 mx-3 "
              >
                Install
              </button>
              <button
                onClick={() => setPrompt(!prompt)}
                className="text-white cursor-pointer px-2 py-1 rounded-sm bg-red-600 mx-3 "
              >
                No
              </button>
            </div>
          </div>
        )}
        <div className="px-4">
          <div className="pt-6">
            <div className="text-center text-primary font-semibold text-2xl">
              Welcome to Chanels
            </div>
          </div>
          <div className="w-[full] h-auto rounded-md px-6 pb-6 pt-2">
            <div className="font-light text-center text-gray-600">
              Fill the fields below to log into your account.
            </div>
            {/* hidden */}
            <div className="hidden grid-cols-2 gap-10 mt-2">
              {/* <div className="  grid-cols-2 gap-10 mt-2"> */}
              <div
                className={
                  tabState == 1
                    ? "cursor-pointer text-center p-2 bg-gray-800 text-white"
                    : "cursor-pointer text-center p-2"
                }
                onClick={() => settabState(1)}
              >
                User
              </div>
              <div
                className={
                  tabState == 2
                    ? "cursor-pointer text-center p-2 bg-gray-800 text-white"
                    : "cursor-pointer text-center p-2"
                }
                onClick={() => settabState(2)}
              >
                System Admin
              </div>
            </div>
            <hr className="mt-1" />
            {tabState == 1 && (
              <div className="">
                {warninglabel.text && (
                  <div
                    className={
                      warninglabel.color == "orange"
                        ? "mt-2 text-red-500 text-center"
                        : ""
                    }
                  >
                    You have a few attempts before further login attempts are
                    frozen for 24 hours.
                    <Link
                      to="/forgotpassword"
                      className="px-2 underline text-blue-300"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                )}
                {/* Lastname */}
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Username
                  </label>
                  <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[55px]">
                    <div className="d-inline flex items-center justify-center px-2">
                      <AiOutlineUser
                        size={20}
                        color="text-primary"
                        className="text-primary"
                      />
                    </div>
                    <div className="ml-2 w-full">
                      <input
                        required
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        type={"text"}
                        placeholder={"Enter your username"}
                        className="block placeholder:text-sm placeholder:font-thin w-full outline-none border-[0px]"
                      />
                    </div>
                  </div>
                </div>

                {/* Password */}
                <div className="mt-4">
                  <div className="flex items-center justify-between">
                    <label className="text-primary font-normal text-sm">
                      Password
                    </label>
                    <label
                      onClick={() => navigate("/forgotpassword")}
                      className="text-primary underline cursor-pointer font-normal text-sm"
                    >
                      Forgot Password
                    </label>
                  </div>

                  <div className="flex items-center justify-between w-100 border-[1px] rounded-sm bg-white border-gray-300 mt-2 h-[55px]">
                    <div className="flex w-full">
                      <div className="d-inline flex items-center justify-center px-2">
                        <AiOutlineKey
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      </div>
                      <div className="ml-2 w-full">
                        <input
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          type={eye ? "text" : "password"}
                          placeholder={"Enter your password"}
                          className=" block placeholder:text-sm placeholder:font-thin outline-none border-[0px] w-full"
                        />
                      </div>
                    </div>

                    <div
                      className="px-3 cursor-pointer"
                      onClick={() => seteye(!eye)}
                    >
                      {eye ? (
                        <AiOutlineEye
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* Button */}
                <div className=" w-100 mt-7">
                  <Button
                    text={"Login"}
                    loading={loading}
                    onClick={handleLogin}
                  />
                </div>

                <div className="w-100 mt-4">
                  <div className="text-primary text-center font-normal py-3">
                    {/* Need help with your account? */}
                  </div>
                  <Link
                    to="/createaccount"
                    className="underline w-100 block cursor-pointer text-sm font-light text-secondary text-center"
                  >
                    <span className="font-semibold text-1xl pl-2">
                      Don't have an account? Create one
                    </span>
                  </Link>
                </div>
              </div>
            )}

            {tabState == 2 && (
              <div className="">
                {/* Lastname */}
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Username
                  </label>
                  <div className="flex bg-white items-center w-100 border-[1px] rounded-sm border-gray-300 mt-2 h-[55px]">
                    <div className="d-inline flex items-center justify-center px-2 ">
                      <AiOutlineUser
                        size={20}
                        color="text-primary"
                        className="text-primary"
                      />
                    </div>
                    <div className="ml-2 w-full">
                      <input
                        required
                        value={username}
                        onChange={(e) => setusername(e.target.value)}
                        type={"text"}
                        placeholder={"Enter your username"}
                        className=" placeholder:text-sm w-full placeholder:font-thin outline-none border-[0px]"
                      />
                    </div>
                  </div>
                </div>

                {/* Password */}
                <div className="mt-4">
                  <label className="text-primary font-normal text-sm">
                    Password
                  </label>
                  <div className="flex items-center justify-between w-100 border-[1px] rounded-sm bg-white border-gray-300 mt-2 h-[55px]">
                    <div className="flex w-full">
                      <div className="d-inline flex items-center justify-center px-2">
                        <AiOutlineKey
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      </div>
                      <div className="ml-2 w-full">
                        <input
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          type={eye ? "text" : "password"}
                          placeholder={"Enter your password"}
                          className=" placeholder:text-sm placeholder:font-thin outline-none border-[0px] w-full"
                        />
                      </div>
                    </div>

                    <div
                      className="px-3 cursor-pointer"
                      onClick={() => seteye(!eye)}
                    >
                      {eye ? (
                        <AiOutlineEye
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          size={20}
                          color="text-primary"
                          className="text-primary"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* Button */}

                <div className="w-100 mt-4">
                  <div className="text-primary text-center font-normal py-3">
                    {/* Need help with you account? */}
                  </div>
                  <Link
                    to="/createaccount"
                    className="underline w-100 block cursor-pointer text-sm font-light text-secondary text-center"
                  >
                    Don't have an account?
                    <span className="font-semibold text-1xl pl-2">
                      Create an account instead.
                    </span>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
