const EmptyState = ({ children }) => {
  return (
    <div className=" h-fullrounded-md w-full flex items-center justify-center">
      <div className="flex items-center justify-center flex-col ">
        {children}
      </div>
    </div>
  );
};

export default EmptyState;
