import moment from "moment";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Rule from "../../components/Rule/Index";
import { IoNotificationsOutline } from "react-icons/io5";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { NotificationFormatter } from "../../helperFuncs";
import NotiImg from "../../assets/svg/2246841_bell_notification_one notification_twitter_icon.svg";
import axios from "../../utils/axios";
import { errorBlock } from "../../controllers/errorBlock";
import DataTable, { TableColumn } from "react-data-table-component";
import BackBtn from "../../components/BackBtn";
import RequestDetails from "../../components/Request/Modal/RequestDetails";
import IncomingRequestTable from "../../components/Request/Table/IncomingRequestTable";

const IncomingRequest = () => {
  const [refresh, setrefresh] = useState(false);
  const [loading, setloading] = useState(false);
  const [modal, setmodal] = useState(false);
  const [details, setdetails] = useState({});
  const [request, setrequest] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        let res = await axios.post("/submeter/getreceivedchanneladdrequests", {
          limit: 100,
          offset: 0,
        });

        setrequest(res?.data.body);
        // setusers();
        setloading(false);
      } catch (err) {
        errorBlock(err);
      }
    })();
    return () => {};
  }, [refresh]);

  return (
    <Layout>
      <>
        {modal && (
          <RequestDetails
            open={modal}
            setopen={setmodal}
            details={details}
            setdetails={setdetails}
            refreshBtn={setrefresh}
          />
        )}
        <div className="bg-white drop-shadow-md rounded-md mt-6 mb-10">
          <div className="px-4">
            <BackBtn />
            <PageTitle
              title={"Incoming request"}
              caption={"All your incoming request"}
            />
          </div>
          <Rule />
          <div className="px-4 py-2">
            <div className=" h-[90vh] rounded-md p-3">
              {!request ? (
                <div className="flex items-center justify-center h-full">
                  <div className="">
                    <div className="flex items-center justify-center">
                      <img src={NotiImg} alt="" className="w-[70px] h-[70px]" />
                    </div>
                    <div className="font-Kanit mt-3 font-semibold ">
                      All your incoming request
                    </div>
                  </div>
                </div>
              ) : (
                <IncomingRequestTable
                  data={request}
                  setdetails={setdetails}
                  setmodal={setmodal}
                />
              )}
            </div>
          </div>
        </div>
        <div className="pb-[50px]"></div>
      </>
    </Layout>
  );
};

export default IncomingRequest;
