const FaultsCard = ({ data }) => {
  return (
    <div
      key={data}
      className="border-[1px] overflow-hidden shadow-md relative w-[220px] border-gray-300 rounded-md h-full"
    >
      <div className="flex flex-col items-center w-full justify-between">
        <div className="bg-gray-200 text-[13px] text-center p-1 w-full">
          3 fault detected
        </div>
        <div className="font-bold p-2 text-center text-[16px]">
          Office Lights
        </div>
        <div
          className={
            data > 200
              ? "text-red-600 text-[40px] text-o font-bold"
              : "text-orange-300 text-[40px] text-o font-bold"
          }
        >
          {data} <sub className="text-[13px]">volts</sub>
        </div>
        <div className="text-center absolute top-[140px] text-[13px] p-2 border-t-[1px] border-t-gray-300">
          Device experienced a voltage drop
        </div>
      </div>
    </div>
  );
};

export default FaultsCard;
