import "./style.css";
import React, { useRef, useState, useEffect } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { IoLogOut } from "react-icons/io5";
import { deleteToken, getToken } from "../../utils/token";
import { FiLogOut } from "react-icons/fi";
import SideBarFooter from "./Footer";
import { IoIosArrowDown } from "react-icons/io";
import toast from "react-hot-toast";
import { data } from "./data";
import NavIcon from "./NavIcon";
import { themeColor } from "../../constant/color";
import { postLogout } from "../../redux/middleware/auth.action";
import { channelController } from "../../controllers/channelController";
import axios from "../../utils/axios";
import Skimmer from "../Loader/Skimmer";
import { MdKeyboardArrowDown } from "react-icons/md";

const SideNav = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [mouse, setMouse] = useState(false);
  const navigate = useNavigate();
  const handlenavItems = () => {
    setMouse((prev) => true);
  };
  const handleMouseOut = () => {
    setMouse((prev) => false);
  };
  const navLink = useRef();
  const [name, setName] = useState("AK");
  const [fullName, setfullName] = useState("");
  const [settingsModal, setSettingsModal] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const [meterModal, setMeterModal] = useState(false);
  const [role, setRole] = useState("");
  const [selectedTab, setselectedTab] = useState("");
  const [loadingmeter, setloadingmeter] = useState(true);
  const [meter, setmeter] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setloadingmeter(true);
        let res = await axios.post("/submeter/getalluserdevices", {});
        let result = channelController(res);
        console.log(result, "result of meter");
        if (result?.type !== "success") {
          toast[result.type](result?.message);
          setloadingmeter(false);
          return;
        }
        setmeter(result.message.body);
        setloadingmeter(false);
      } catch (err) {
        toast.error(err.message);
        setloadingmeter(false);
      }
    })();
    return () => {};
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let role = await getToken("spiral_role");
        setRole(role);
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  return (
    <div className="side-nav relative hidden md:hidden px-1 lg:block side-nav_bg h-screen w-[240px] xl:w-[270px] drop-shadow-md bg-gray-900">
      <div className="mt-8 flex items-center px-6">
        <div className="hidden w-[30px] h-[30px] border-[1px] rounded-full border-gray-400"></div>
        <div className="font-bold pl-2 text-[17px] text-white">Chanels</div>
      </div>

      <div className="pt-3 h-[80%] overflow-x-hidden overflow-y-scroll">
        {loadingmeter
          ? Array.from(Array(8)).map((_, i) => (
              <div className="w-full mt-4" key={i}>
                <Skimmer key={i} heigth={45} bg={"#263146"} />
              </div>
            ))
          : data.user.map((i) => (
              <div className="" key={i.id}>
                <div
                  onClick={() => {
                    if (i.children.length === 0) {
                      navigate(`${i.link}`);
                    } else {
                      if (selectedTab == "") {
                        setselectedTab(i.title);
                      } else {
                        setselectedTab("");
                      }
                    }
                  }}
                  to={i?.link}
                  style={{
                    background:
                      i?.link == location.pathname ? "#263146" : "transparent",
                  }}
                  className={
                    // hidden the following tabs for none meter owners
                    // (meter.length == 0 && i?.title == "Meters") ||
                    (meter.length == 0 && i?.title == "User Management") ||
                    (meter.length == 0 && i?.title == "Payment plans")
                      ? `hidden items-start justify-between border-1 border-white text-white px-4 py-3 my-3 cursor-pointer hover:bg-[#263146]`
                      : `flex items-start justify-between border-1 border-white text-white px-4 py-3 my-3 cursor-pointer hover:bg-[#263146]`
                  }
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center justify-center pt-[2px]">
                      <NavIcon title={i?.title} link={i?.link} />
                    </div>
                    <div className="text-[16px] font-light pl-4">
                      {i?.title}
                    </div>
                  </div>

                  {i?.children.length != 0 && (
                    <div className="flex items-center justify-center">
                      <MdKeyboardArrowDown size={20} className="mt-1" />
                    </div>
                  )}
                </div>
                {selectedTab == i?.title && (
                  <>
                    <Link
                      to="/allpaymentplans"
                      // onClick={() => setselectedTab("")}
                      className={`px-4 py-2 my-2 ml-4 flex items-start justify-between border-1 border-white text-white  cursor-pointer hover:bg-[#263146]`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center justify-center pt-[2px]">
                          <NavIcon
                            title={"Payment plans"}
                            link={"allpaymentplans"}
                          />
                        </div>
                        <div className="text-[16px] font-light pl-4">
                          Payment plans
                        </div>
                      </div>
                    </Link>
                    <Link
                      to="/meterclass"
                      // onClick={() => setselectedTab("")}
                      className={`px-4 py-2 my-2 ml-4 flex items-start justify-between border-1 border-white text-white  cursor-pointer hover:bg-[#263146]`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center justify-center pt-[2px]">
                          <NavIcon
                            title={"Payment plans"}
                            link={"allpaymentplans"}
                          />
                        </div>
                        <div className="text-[16px] font-light pl-4">
                          Meter class
                        </div>
                      </div>
                    </Link>
                  </>
                )}
              </div>
            ))}

        {/* {role == "Customer Representative" && (
          <Tree treeData={data.customerRep} />
        )}
        {role == "System Manager" && <Tree treeData={data.sysdmin} />}
        {role == "Project Manager" && <Tree treeData={data.projectmanager} />}
        {role == "Sales Agent" && <Tree treeData={data.salesagent} />}
        {role == "Customer" && <Tree treeData={data.customers} />}
        {(role == "User" || !role) && <Tree treeData={data.user} />} */}
      </div>
      <div
        className="absolute bottom-6 cursor-pointer w-full"
        onClick={async () => {
          try {
            dispatch(postLogout());
            navigate("/");
            // localStorage.clear();
          } catch (err) {
            toast.err(err.message);
          }
        }}
      >
        <div className="flex pl-4">
          <div className="flex items-center justify-center pt-[2px]">
            {/* 6c6c6c */}
            <FiLogOut size={18} color="#fff" />
          </div>
          {/* text-[#6c6c6c] */}
          <div className="text-[16px] font-normal text-white pl-4 ">
            Log out
          </div>
        </div>
      </div>
      <div
        className=""
        style={{
          position: "absolute",
          bottom: "20px",
          marginLeft: "5px",
        }}
      >
        {/* <SideBarFooter /> */}
      </div>
    </div>
  );
};

export default SideNav;

const Tree = ({ treeData }) => {
  return (
    <>
      {treeData.map((node, i) => (
        <TreeNode node={node} key={i} />
      ))}
    </>
  );
};

const TreeNode = ({ node }) => {
  const { children, title, icon, link } = node;
  const location = useLocation();
  const [showChildren, setShowChildren] = useState(false);
  const handleClick = () => {
    setShowChildren(!showChildren);
  };
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState("");
  const [site, setSite] = useState("");
  useEffect(() => {
    (async () => {
      try {
        let role = await getToken("spiral_role");
        let site = await getToken("spiral_site");
        setRole(role);
        setSite(site);
        setLoading(false);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);
  return (
    <>
      {/* <div className="border-2 border-white" onClick={() => handleClick()}> */}
      <Link
        to={
          role == "Customer Representative" && title == "Dashboard"
            ? `${link}/${site}`
            : role == "Sales Agent" && title == "Dashboard"
            ? `${link}/${site}`
            : link.replace(":sitename", site)
        }
        className="flex items-start px-4 py-3 my-3 cursor-pointer"
      >
        <div className="flex items-center justify-center pt-[2px]">
          <NavIcon title={title} link={link} />
        </div>
        <div
          className="text-[16px] font-light pl-4 hover:text-white "
          style={{
            color: location.pathname == link ? themeColor.white : "#a3a3a3",
            display:
              title.trim() == "All user roles" &&
              role.trim() !== "System Manager"
                ? "none"
                : "block",
          }}
        >
          {title}
        </div>
      </Link>
      {/* </div> */}
      {showChildren && <Tree treeData={children} />}
    </>
  );
};
