import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import MoneyBgImage from "../../../assets/png/v300batch2-nunoon-13.jpg";
import { useContext, useEffect, useState } from "react";
import { errorBlock } from "../../../controllers/errorBlock";
import { BillingContext } from "../../../context/BillingContext";
import toast from "react-hot-toast";
import { currencyOptions } from "../../../screens/Wallet/walletData";
const WalletBalance = ({
  currency,
  setcurrency,
  balance,
  setbalance,
  loadbalance,
  setloadbalance,
  currencyOptions,
}) => {
  const handlePrevCurrency = () => {
    if (currency === 0) {
      setcurrency(currencyOptions.length - 1);
    } else {
      setcurrency(currency - 1);
    }
  };

  const handleNextCurrency = () => {
    if (currency === currencyOptions.length - 1) {
      setcurrency(0);
    } else {
      setcurrency(currency + 1);
    }
  };

  return (
    <div className="h-[auto] overflow-hidden relative border-[1px] border-gray-300  p-2 rounded-md">
      <div className="flex z-40 items-center justify-between">
        <div className="font-light text-[15px]">Wallet balance</div>
        {/* bg-[#1414a214] */}
        <div className="flex w-[auto] border-[1px] border-gray-300 rounded-md items-center justify-between ">
          <div
            className="border-r-[1px] cursor-pointer border-gray-300 p-2"
            onClick={handlePrevCurrency}
          >
            <IoIosArrowRoundBack size={20} />
          </div>
          <div className="flex items-center cursor-pointer px-1">
            <div className="px-1 font-bold">
              {currencyOptions[currency].tag}
            </div>
          </div>
          <div
            onClick={handleNextCurrency}
            className="border-l-[1px] cursor-pointer border-gray-300 p-2"
          >
            <IoIosArrowRoundForward size={20} />
          </div>
        </div>
      </div>
      <div className="text-bold font-bold pt-1 text-[30px] mt-3 ">
        ₦{balance}
      </div>
      <div className="grid grid-cols-2 gap-4 mt-3">
        <div className="cursor-pointer p-2 mt-3 bg-black text-white border-[2px] text-center rounded-md border-gray-500">
          Add money
        </div>
        <div className="cursor-pointer p-2 mt-3 bg-white text-black border-[2px] text-center rounded-md border-gray-500">
          Add currency
        </div>
      </div>
    </div>
  );
};

export default WalletBalance;
