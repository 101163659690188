import Chart from "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
import { useRef } from "react";
import moment from "moment";

const EnergyCharts = ({ data }) => {
  const chartRef = useRef();
  const date = data?.map((i) => moment(i?.pqdatetime).format("ll"));
  const ene = data?.map((i) => i?.activeenergyln1);
  const cur = data?.map((i) => i?.currentln1);
  const vol = data?.map((i) => i?.voltageln1);

  return (
    <Line
      data={{
        labels: date, // x axis
        datasets: [
          {
            type: "bar",
            label: "Voltage",
            fill: false,
            backgroundColor: "#0183d0e2",
            data: vol,
            borderColor: "#0183d0",
            radius: 1,
            borderWidth: 0,
            pointBorderWidth: 2,
            tension: 0.4,
          },
          {
            type: "bar",
            label: "Current",
            fill: false,
            backgroundColor: "tomato",
            data: cur,
            borderColor: "tomato",
            radius: 1,
            borderWidth: 0,
            pointBorderWidth: 2,
            tension: 0.4,
          },
          {
            type: "bar",
            label: "Energy",
            fill: false,
            backgroundColor: "orange",
            data: ene,
            borderColor: "",
            radius: 1,
            borderWidth: 0,
            pointBorderWidth: 2,
            tension: 0.4,
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: true,

          title: {
            display: false,
            text: "Consumption chart",
          },
          tooltip: {
            // callbacks: {
            //   label: function (tooltipItem, data) {
            //     return numFormtter(parseInt(tooltipItem.parsed.y));
            //   },
            // },
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: true,
            },
          },
          y: {
            display: true,
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default EnergyCharts;

// <Bar
//   ref={chartRef}
//   data={{
//     labels: [
//       "Sunday",
//       "Monday",
//       "Tuesday",
//       "Wednesday",
//       "Thursday",
//       "Friday",
//       "Saturday",
//     ], // x axis
//     datasets: [
//       {
//         // type: "line",
//         label: "office",
//         fill: true,
//         backgroundColor: "#2A84FF",
//         data: [10, 30, 20, 55, 43, 75, 12],
//         radius: 5,
//         borderRadius: 3,
//         pointBorderWidth: 5,
//         tension: 0.4,
//         barThickness: 40,
//       },

//       {
//         // type: "line",
//         label: "Store",
//         fill: true,
//         backgroundColor: "#B3E7FC",
//         data: [10, 30, 20, 55, 43, 75, 34],
//         radius: 5,
//         borderRadius: 3,
//         pointBorderWidth: 5,
//         tension: 0.4,
//         barThickness: 40,
//       },
//       {
//         // type: "line",
//         label: "Hostel",
//         fill: true,
//         backgroundColor: "#FFBD99",
//         data: [10, 30, 20, 55, 43, 75, 20],
//         radius: 5,
//         borderRadius: 3,
//         pointBorderWidth: 5,
//         tension: 0.4,
//         barThickness: 40,
//       },
//       {
//         // type: "line",
//         label: "Room",
//         fill: true,
//         backgroundColor: "orange",
//         data: [10, 30, 20, 55, 43, 75, 29],
//         radius: 5,
//         borderRadius: 5,
//         pointBorderWidth: 5,
//         tension: 0.4,
//         barThickness: 40,
//       },
//     ],
//   }}
//   options={{
//     maintainAspectRatio: false,
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "bottom",
//       },
//       title: {
//         display: false,
//       },
//       tooltip: {},
//     },
//     scales: {
//       x: {
//         display: true,
//         stacked: true,
//         grid: {
//           display: false,
//         },
//       },
//       y: {
//         stacked: true,
//         display: true,
//         grid: {
//           display: true,
//           borderDash: [10],
//           borderDashOffset: 20,
//           borderWidth: 0,
//           color: "#eee",
//         },
//       },
//     },
//   }}
// />
