import Chart from "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
import { useRef } from "react";
import moment from "moment";

const ChannelEnergyConsumption = ({ data }) => {
  const chartRef = useRef();
  function getRandomIntInclusive(min, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
  }
  const date = data?.map((i) => moment(i?.pqdatetime).format("ll"));
  const ene = data?.map((i) => i?.activeenergyln1);
  const cur = data?.map((i) => i?.currentln1);
  const vol = data?.map((i) => i?.voltageln1);
  return (
    <Line
      ref={chartRef}
      data={{
        // [
        //   "Sunday",
        //   "Monday",
        //   "Tuesday",
        //   "Wednesday",
        //   "Thursday",
        //   "Friday",
        //   "Saturday",
        // ]
        labels: date, // x axis
        datasets: [
          {
            // type: "line",
            label: "Energy",
            barThickness: 40,
            fill: false,
            backgroundColor: "#2A84FF",
            borderColor: "#2A84FF",
            data: ene,
            radius: 2,
            // borderRadius: 1,
            pointBorderWidth: 5,
            tension: 0.0,
          },

          {
            // type: "line",
            label: "Voltage",
            barThickness: 40,
            fill: false,
            backgroundColor: "#B3E7FC",
            data: vol,
            radius: 5,
            borderColor: "#B3E7FC",
            // borderRadius: 1,
            pointBorderWidth: 2,
            tension: 0.0,
          },
          {
            // type: "line",
            label: "Current",
            fill: false,
            backgroundColor: "#FFBD99",
            borderColor: "#FFBD99",
            data: cur,
            radius: 2,
            barThickness: 40,
            // borderRadius: 1,
            pointBorderWidth: 2,
            tension: 0.0,
          },
          //   {
          //     // type: "line",
          //     label: "Room",
          //     barThickness: 40,
          //     fill: true,
          //     backgroundColor: "orange",
          //     data: [10, 30, 20, 55, 43, 75, 29],
          //     radius: 2,
          //     borderRadius: 2,
          //     pointBorderWidth: 2,
          //     tension: 0.4,
          //   },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,

        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: false,
          },
          tooltip: {},
        },
        scales: {
          x: {
            // display: true,
            // stacked: true,
            grid: {
              display: false,
            },
          },
          y: {
            // stacked: true,
            display: true,
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default ChannelEnergyConsumption;
