import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { themeColor } from "../../../../../constant/color";
const VendHistory = ({ data }) => {
  const chartRef = useRef();
  // const [data, setData] = useState("");
  // const [labels, setLabels] = useState("");

  return (
    <Bar
      ref={chartRef}
      data={{
        labels: data.map((i) => moment(i.date).format("ll")), // x axis
        datasets: [
          {
            type: "bar",
            label: "",
            fill: true,
            data: data.map((i) => moment(i.amount).format("ll")),
            backgroundColor: "dodgerblue",
            radius: 2,
            borderRadius: 2,
            pointBorderWidth: 1,
            barThickness: 30,
            tension: 0.4,
          },
          //   {
          //     type: "line",
          //     label: "",
          //     fill: false,
          //     data: [10, 30, 20, 55, 43, 75],
          //     borderColor: "tomato",
          //     radius: 2,
          //     borderWidth: 2,
          //     pointBorderWidth: 1,
          //     tension: 0.4,
          //   },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: false,
          title: {
            display: true,
            text: "Recharge history across your meter.",
          },
          tooltip: {
            // callbacks: {
            //   label: function (tooltipItem, data) {
            //     return numFormtter(parseInt(tooltipItem.parsed.y));
            //   },
            // },
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            grid: {
              display: true,
              borderDash: [10],
              borderDashOffset: 20,
              borderWidth: 0,
              color: "#eee",
            },
          },
        },
      }}
    />
  );
};

export default VendHistory;
