export const currencyOptions = [
  {
    id: 1,
    tag: "RWF",
  },
  {
    id: 2,
    tag: "NGN",
  },
  {
    id: 3,
    tag: "MTN MoMo RWF",
  },
];
