import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import { billingReducer } from "../slice/billing.slice";
import { paymentReducer } from "../slice/payment.slice";
import { profileReducer } from "../slice/profileSlice";
export const rootReducer = combineReducers({
  authReducer: authReducer,
  billing: billingReducer,
  payment: paymentReducer,
  profiles: profileReducer,
});
