import { createSlice } from "@reduxjs/toolkit";
import { planInfo } from "../middleware/payment.action";

const initialState = {
  allplans: [],
  channelPlans: [],
  planInfo: {},
  pending: {
    loadingplans: true,
    loadingchannelplans: true,
    loadingplaninfo: true,
  },
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    getallPlans: (state, action) => {
      state.allplans = action.payload;
    },
    getchannelPlans: (state, action) => {
      state.channelPlans = action.payload;
    },
    getplanInfo: (state, action) => {
      state.planInfo = action.payload;
    },
  },
});

export const { getallPlans, getchannelPlans, getplanInfo } =
  paymentSlice.actions;

export const paymentReducer = paymentSlice.reducer;
