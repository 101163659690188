import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const IncomingRequestTable = ({ data, setdetails, setmodal }) => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [selectedplan, setselectedplan] = useState(null);

  const columns = [
    {
      name: "Date sent",
      center: true,
      selector: (row) => row.datesent,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex items-center">
            <div className="px-1">
              <FcCalendar size={18} />
            </div>
            <div className="text-[12px]">
              {" "}
              {moment(item.datesent).format("lll")}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Sender",
      selector: (row) => row.sender,
      center: true,
      grow: 2,
    },
    {
      name: "Note",
      center: true,
      selector: (row) => row.note,
      sortable: true,
      grow: 3,
    },
    {
      name: "Access given",
      center: true,
      selector: (row) => row.accesscontrol,
      sortable: true,
      grow: 2,
    },
    {
      name: "Action",
      selector: (row) => row.channelid,
      grow: 2,
      cell: (item) => {
        return (
          <div className="flex relative rounded-md items-center justify-around">
            <div
              onClick={() => {
                setdetails(item);
                setmodal(true);
              }}
              className="border-[1px] cursor-pointer text-secondary mr-3 text-[13px] border-secondary px-4 py-1 rounded-md w-[fit-content]"
            >
              Details
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        style={{
          height: "100%",
        }}
        columns={columns}
        data={data}
        pagination
        paginationPerPage={10}
      />
    </>
  );
};

export default IncomingRequestTable;

// <table>
//   <thead>
//     <th className="text-start">Date sent</th>
//     <th className="text-center">Sender</th>
//     <th className="text-center">Note</th>
//     <th className="text-center">Access given</th>
//     <th className="text-center">Status</th>
//     <th className="text-center">Action</th>
//   </thead>
//   <tbody>
//     {request.map((i, index) => (
//       <tr
//         key={index}
//         className="cursor-pointer even:bg-gray-50 hover:bg-gray-50"
//       >
//         <td className="text-start">
//           <div className="flex justify-start items-center">
//             <div className="">
//               <FcCalendar size={21} />
//             </div>
//             <div className="pl-2">{moment(i.datesent).format("lll")}</div>
//           </div>
//         </td>
//         <td className="text-center">{i.sender}</td>
//         <td className="text-center">{i.note}</td>
//         <td className="text-center">
//           {i.accesscontrol == "single" ? "Single" : "Shared"}
//         </td>
//         <td className="text-center">{i.status}</td>
//         <td className="text-start flex justify-center items-center">

//         </td>
//       </tr>
//     ))}
//   </tbody>
// </table>;
