import { applyMiddleware, compose, createStore } from "redux";
import { thunk } from "redux-thunk";
import { rootReducer } from "./reducers";
import { composeWithDevTools } from "@redux-devtools/extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function configureStore(preloadedstate) {
  const middleware = [thunk];
  const middlewareEnhancers = applyMiddleware(...middleware);

  const enhancers = [middlewareEnhancers];
  const composeEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(persistedReducer, preloadedstate, composeEnhancers);

  let persistor = persistStore(store);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }
  return { store, persistor };
}
