import {
  AiFillEye,
  AiOutlineEye,
  AiOutlineKey,
  AiOutlineUser,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { Button } from "../../../components/Button";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BGImg from "../../../assets/png/v300batch2-nunoon-13.jpg";
import { errorBlock } from "../../../controllers/errorBlock";
import axios from "axios";
import toast from "react-hot-toast";
import { headers } from "../../../utils/headers";
const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setloading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [eye, seteye] = useState(false);
  const navigate = useNavigate();
  const [showresend, setshowresend] = useState(false);
  const onSubmit = async (data) => {
    try {
      setloading(true);
      console.log(
        code,
        ":code",
        data.username,
        ":username",
        data.password,
        ":password"
      );
      let res = await axios.post(
        "https://api.app.chanels.io/auth/mms/completecodeverification",
        {
          callerid: data.username,
          verificationcode: code,
          newpassword: data.password,
          subdomain: "app",
        },
        {
          headers: {
            Version: "0.1",
          },
        }
      );

      if (res.data.repsonse == "expired") {
        toast.success(`Link expired. Click on resend link.`);
        setshowresend(true);
        setloading(false);
        return;
      }
      // A new reset password link has been sen to you email verification.
      if (res.data.response == "success") {
        setloading(false);
        toast.success("Password reset successful. Login to continue");
        navigate("/");
        return;
      }
      setloading(false);
    } catch (error) {
      errorBlock(error);
      setloading(false);
    }
  };

  const handleResend = async () => {
    try {
      if (!searchParams.get("username")) {
        toast.error("Please provide an email address, to continue");
        return;
      }

      // settokenloading(true);
      let res = await axios.post(
        "https://api.app.chanels.io/authselfhelp/resendverificationcode",
        {
          callerid: "glen",
          // callerid: location.search.split("&")[0].split("=")[1],
          subdomain: "app",
        },
        headers
      );

      if (res.data.response == "success") {
        // settokenloading(false);
        toast.success("A code has been sent to your email address.");
        return;
      } else if (res.data.response == "failure") {
        // settokenloading(false);
        toast.error("Failed to send code.");
        return;
      }
    } catch (err) {
      toast.error(err.message);
      // settokenloading(false);
    }
  };

  return (
    <div className="h-screen  grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 place-items-center place-content-center gap-10">
      <div
        className="h-screen absolute top-0 left-0 w-screen"
        style={{
          background: `url(${BGImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "center",
          backgroundSize: "cover",
          opacity: 0.3,
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className="bg-white w-[95%] md:w-[450px] h-auto drop-shadow-md rounded-md p-6">
        <div className="text-center text-primary font-semibold text-2xl pb-4">
          Chanels
        </div>
        <div className="text-center ">
          Reset your default password to continue.
        </div>
        <hr className="my-4" />
        <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
          {/* New Password */}
          <div className="mt-6">
            <label className="text-primary font-normal text-sm">Username</label>
            <div className="flex items-center justify-between w-100 border-[1px] rounded border-gray-300 bg-gray-50 mt-2 h-[43px]">
              <div className="flex w-full">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineUser
                    color="text-primary"
                    className="text-primary"
                  />
                </div>
                <div className="ml-2 w-full">
                  <input
                    {...register("username", { required: true })}
                    type={"text"}
                    placeholder={"Enter your username"}
                    className="bg-transparent placeholder:text-sm w-full placeholder:font-thin outline-none border-[0px]"
                  />
                </div>
              </div>
            </div>
            {errors.username && (
              <span className="text-red-400 text-[12px]">
                username field is required
              </span>
            )}
          </div>
          {/* New Password */}
          <div className="mt-6">
            <div className="flex items-center justify-between ">
              <label className="text-primary font-normal text-sm">
                New Password
              </label>
              {showresend && (
                <div
                  className=" underline cursor-pointer text-secondary"
                  onClick={handleResend}
                >
                  Resend Link
                </div>
              )}
            </div>

            <div className="flex items-center justify-between w-100 border-[1px] rounded border-gray-300 bg-gray-50 mt-2 h-[43px]">
              <div className="flex w-full">
                <div className="d-inline flex items-center justify-center px-2">
                  <AiOutlineKey color="text-primary" className="text-primary" />
                </div>
                <div className="ml-2 w-full">
                  <input
                    {...register("password", { required: true })}
                    type={eye ? "text" : "password"}
                    placeholder={"Enter your new password"}
                    className="bg-transparent placeholder:text-sm w-full placeholder:font-thin outline-none border-[0px]"
                  />
                </div>
              </div>
              <div className="px-3 cursor-pointer" onClick={() => seteye(!eye)}>
                {!eye ? (
                  <AiOutlineEye
                    size={15}
                    color="text-primary"
                    className="text-primary"
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    size={15}
                    color="text-primary"
                    className="text-primary"
                  />
                )}
              </div>
            </div>
            {errors.password && (
              <span className="text-red-400 text-[12px]">
                password field is required
              </span>
            )}
          </div>
          {/* Button */}
          <div className=" w-100 mt-7 ">
            <Button text={"Continue"} loading={loading} />
          </div>

          <div className="w-100 mt-4 flex items-center justify-center ">
            <Link
              to="/"
              className="underline w-100 block cursor-pointer w-[fit-content] text-center"
            >
              Have an account? Log in.
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
