import { GiTakeMyMoney, GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { themeColor } from "../../../../constant/color";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { currencyOptions } from "../../../../screens/Wallet/walletData";

const WalletCard = ({ value, title, type, setcurrency, currency }) => {
  const handlePrevCurrency = () => {
    if (currency === 0) {
      setcurrency(currencyOptions.length - 1);
    } else {
      setcurrency(currency - 1);
    }
  };

  const handleNextCurrency = () => {
    if (currency === currencyOptions.length - 1) {
      setcurrency(0);
    } else {
      setcurrency(currency + 1);
    }
  };
  return (
    <div className="p-2 border h-[130px] rounded-md border-r-[1px]">
      <div className="flex items-center justify-between mt-1">
        <div className="w-[100%]">
          <div className={"flex items-center justify-between w-[100%]"}>
            <div className="text-gray-600 font-light">{title}</div>

            {type == "balance" ? (
              <div className="flex w-[auto] border-[1px] border-gray-300 rounded-md items-center justify-between ">
                <div
                  className="border-r-[1px] cursor-pointer border-gray-300 p-2"
                  onClick={handleNextCurrency}
                >
                  <IoIosArrowRoundBack size={20} />
                </div>
                <div className="flex items-center cursor-pointer px-1">
                  <div className="px-1 font-bold">
                    {currencyOptions[currency].tag}
                  </div>
                </div>
                <div
                  onClick={handleNextCurrency}
                  className="border-l-[1px] cursor-pointer border-gray-300 p-2"
                >
                  <IoIosArrowRoundForward size={20} />
                </div>
              </div>
            ) : (
              <div className="flex w-[auto] rounded-md items-center justify-between ">
                {type == "spent" ? (
                  <GiPayMoney size={32} />
                ) : (
                  <GiReceiveMoney size={32} />
                )}
              </div>
            )}
          </div>

          <div className="text-gray-700 mt-6 font-semibold font-kanit text-[24px] pt-1">
            ₦
            {value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <div className="hidden">
          {type == "balance" ? (
            <GiTakeMyMoney size={30} color={themeColor.secondary} />
          ) : type == "spent" ? (
            <GiReceiveMoney size={30} color={themeColor.secondary} />
          ) : (
            <GiPayMoney size={30} color={themeColor.secondary} />
          )}
        </div>
      </div>
    </div>
  );
};

export default WalletCard;
